import Q from 'q';
// 外部客户列表
import external_cutomer_list from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_getExternalCustomerList';

// 国籍
import consumer_web_city_areaLinkage from '@/lib/data-service/haolv-default/consumer_web_city_areaLinkage'

// 添加外部客户
import add_external_cutomer from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_addExternalCustomer';
// 删除外部客户
import delete_external_cutomer from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_delExternalCustomer';
// 外部客户详情
import detail_external_cutomer from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_getCustomerDetail';
// 修改外部客户
import change_external_cutomer from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_updateExternalCustomer';
import consumer_country_queryCountryTwoWord from '@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord'
import consumer_admin_flight_generate_certificateType from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType'
import moment from "moment/moment";
export default {
  data () {
    return {
      value: null,
      loading: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        thpOrName: '',
      },
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 10,
      },
      list: [],
      dialogVisible: false,
      userTitle: '',
      userForm: {
        customerName: '',
        familyName: '',
        givenName: '',
        englishName: '',
        englishSurnames: '',
        birthDate: '',
        email: '',
        phone: '',
        customerSex: '',
        customerType: '',
        certificates: [
          {
            certificateType: 1,
            certificateValue: '',
            issCountryCode: 'CN',
            passengerExpireDate: '',
          }
        ],
        id: '',
        nationality: 'CN',
        remark: '',
      },
      userRules: {
        customerName: [
          { required: true, message: '请输入姓名', trriger: 'blur' },
        ],
        familyName: [
          { required: true, message: '请输入姓', trriger: 'blur' },
          { validator: this.validateFamilyName, trigger: 'blur'}
        ],
        givenName: [
          { required: true, message: '请输入名', trriger: 'blur' },
          { validator: this.validateStaffName, trigger: 'blur'}
        ],
        englishName: [
          {  message: '请输入英文名', trriger: 'blur' },
          {  pattern: /[a-zA-Z]/, message: '请用英文字母', trriger: 'blur' },
        ],
        englishSurnames: [
          {  message: '请输入英文名', trriger: 'blur' },
          {  pattern: /[a-zA-Z]/, message: '请用英文字母', trriger: 'blur' },
        ],
        birthDate: [
          { required: true, message: '请输入出生日期', trriger: 'blur' },
          // { required: true, message: '请输入正确的出生日期', trriger: 'blur' },
        ],
        email: [
          {  message: '请输入邮箱', trriger: 'blur' },
          { pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的邮箱', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trriger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        customerSex: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ],
        certificateType: [
          { required: true, message: '请选择证件类型', trigger: 'change' },
        ],
        certificateValue: [
          { required: true, message: '请输入证件号', trigger: 'blur' },
        ],
        nationality: [
          { required: true, message: '请输入选择国籍', trigger: 'blur' },
        ]
      },
      allocation: {
        customerSex: [
          {  label: '1', text: '男' },
          {  label: '2', text: '女' },
        ],
        certificateType:[
          { value: 1, label: '身份证', disable: false },
          { value: 2, label: '护照', disable: false },
          { value: 4, label: '回乡证', disable: false },
          { value: 5, label: '台胞证', disable: false },
          { value: 6, label: '军官证', disable: false },
          { value: 7, label: '香港身份证', disable: false },
          { value: 11, label: '澳门身份证', disable: false },
          { value: 10, label: '其他', disable: false },
        ],
        nationality: [],
      },
      timer: null,

      activeUserId: '',
    }
  },
  computed: {

  },
  methods: {
    getCertificateType() {
      consumer_admin_flight_generate_certificateType().then(res => {
        let certificateTypes = res.datas;
        certificateTypes.forEach(value => {
          value.value = value.code;
          value.label = value.msg;
          value.disabled = false;
        })
        this.allocation.certificateType = certificateTypes
      })
    },
    cardId_change (ev, index) {
      const certificates = this.userForm.certificates[index];
      const value = ev.target.value;
      if (certificates.certificateType != 1 || !value) return;
      this.userForm.birthDate = value.slice(6, 10) + '-' + value.slice(10, 12) + '-' + value.slice(12, 14);
    },
    indexMethod (index) {
      return (this.form.currentPage - 1) * 10 + index + 1;
    },
    req_list (params) {
      const _this = this;
      _this.list = [];
      _this.loading = true;
      Q.when()
        .then(function () {
          return external_cutomer_list(params);
        })
        .then(function (res) {
          _this.list = res.datas.list;
          _this.pager.total = res.datas.totalCount;
          _this.loading = false;
        })
    },
    req_nationality (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return consumer_country_queryCountryTwoWord();
        })
        .then(function (res) {
          _this.allocation.nationality = res.datas;
        })
    },

    req_add (params) {
      const _this = this;
      this.loading = true;
      Q.when()
        .then(function () {
          return add_external_cutomer(params);
        })
        .then(function (res) {
          _this.loading = false;
          _this.handelClose();
          _this.$message.success('添加成功');
          _this.req_list(_this.form);
        }).catch(()=>{
          _this.loading = false;
      })
    },
    req_delete (params) {
      const _this = this;
      _this.loading = true;
      Q.when()
        .then(function () {
          return delete_external_cutomer(params);
        })
        .then(function (res) {
          _this.loading = false;
          _this.$message.success('已删除');
          _this.req_list(_this.form);
        }).catch(()=>{
          this.loading = false;
      })
    },
    req_detail (params) {
      return Q.when()
        .then(function () {
          return detail_external_cutomer(params);
        })
    },
    req_update_user (params) {
      const _this = this;
      this.loading = true;
      Q.when()
        .then(function () {
          return change_external_cutomer(params);
        })
        .then(function (res) {
          _this.loading = false;
          _this.handelClose();
          _this.$message.success('修改成功');
          _this.req_list(_this.form);
        }).catch(()=>{
          _this.loading = false;
      })
    },
    search () {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    change_search (val) {
      if (!val) {
        this.search();
      }
    },
    add_user (type, row) {
      if (type === 'edit') {
        const _this = this;
        _this.userTitle = '编辑外部客户';
        this.activeUserId = row.userId || '';
        Q.when()
          .then(function () {
            return _this.req_detail({id: row.id, userId: row.userId});
          })
          .then(function (res) {
            const detail = res.datas;
            const form = JSON.parse(JSON.stringify(_this.userForm));
            form.familyName = detail.familyName || '';
            form.givenName = detail.givenName || '';
            form.customerName = detail.customerName;
            form.englishSurnames = detail.englishSurnames || '';
            form.englishName = detail.englishName || '';
            form.birthDate = detail.birthDate;
            form.email = detail.email;
            form.phone = detail.phone;
            form.customerSex = detail.customerSex ? detail.customerSex.toString() : '1';
            form.certificates = detail.certificates;
            form.certificates.forEach(function (item) {
              // item.certificateType = item.certificateType.toString();
              item.issCountryCode = item.issCountryCode || 'CN'
            })
            form.id = detail.id;
            form.nationality = detail.nationality || 'CN';
            form.remark = detail.remark;
            _this.userForm = form;
            _this.dialogVisible = true;
          })
      } else {
        this.userTitle = '新增外部客户'
        this.dialogVisible = true;
      }
    },
    handelClose () {
      this.dialogVisible = false;
        this.userForm = {
          customerName: '',
          familyName: '',
          givenName: '',
          englishName: '',
          birthDate: '',
          email: '',
          phone: '',
          customerSex: '',
          customerType: '',
          certificates: [
            {
              certificateType: 1,
              certificateValue: '',
              issCountryCode: 'CN',
              passengerExpireDate: '',
            }
          ],
          id: '',
          nationality: 'CN',
        };
        // this.$refs.userForm.resetFields();
        this.$refs.userForm.clearValidate();
    },
    delete_user (row) {
      this.$confirm('确认删除此帐号？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      })
        .then(() => {
          this.req_delete({id: row.id, userId: row.userId});
        })
        .catch(() => {

        })
    },
    add_voucher () {
      const certificates = this.userForm.certificates;
      if (certificates.length === 3) return;

      const certificateType = this.allocation.certificateType;
      const selectType = certificates.map(item => item.certificateType);
      let type = '';
      for (let i = 0; i < certificateType.length; i++) {
        const item = certificateType[i];
        if (selectType.indexOf(item.value) === -1) {
          type = item.value;
          this.userForm.certificates.push({
            certificateType: type,
            certificateValue: '',
            issCountryCode: 'CN',
            countryCode: 'CN',
            passengerExpireDate: '',
            userId: this.activeUserId
          });
          return;
        };
      }
    },
    visible_change (value) {
      const certificates = this.userForm.certificates;
      const selectType = certificates.map(item => item.certificateType);
      const certificateType = this.allocation.certificateType;
      certificateType.forEach(item => {
        if (item.value === value) {
          item.disable = false;
        } else if (selectType.indexOf(item.value) === -1) {
          item.disable = false;
        } else {
          item.disable = true;
        }
      })
    },
    delete_voucher (index) {
      this.userForm.certificates.splice(index, 1);
    },
    commit_user () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          const userForm = JSON.parse(JSON.stringify(this.userForm));
          userForm.customerName = userForm.familyName + userForm.givenName
          if (this.userTitle === '新增外部客户') {
            this.req_add(userForm);
          } else if (this.userTitle === '编辑外部客户') {
            this.req_update_user(userForm);
          }
        } else {
          return false;
        }
      });
    },
    change_pageSize (pageSize) {
      this.form.pageSize = pageSize;
      this.search();
    },
    change_currentPage (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form);
    },
    cardExpireDateDisabledDate(val) {
      const now = moment(val).format('YYYY-MM-DD')
      const nowTime = moment(now + ' 00:00:00').valueOf()
      const today = moment().format('YYYY-MM-DD')
      const todayTime = moment(today + ' 00:00:00').valueOf();
      return nowTime < todayTime
    },
    // 检验中文名
    validateStaffName(rule, value, callback) {
      let hasMustChinaCertificate = false;
      this.userForm.certificates.forEach(value1 => {
        if ([1, 12, '1', '12'].indexOf(value1.certificateType) > -1) {
          hasMustChinaCertificate = true;
        }
      })

      const regExp = hasMustChinaCertificate ? /^[\u4e00-\u9fa5]+$/ : /^[\u0391-\uFFE5A-Za-z]+$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(hasMustChinaCertificate ? '只能输入中文' : '只能输入中文、英文')
      }
    },
    validateFamilyName(rule, value, callback) {
      let hasMustChinaCertificate = false;
      this.userForm.certificates.forEach(value1 => {
        console.log(value1.certificateType);
        if ([1, 12, '1', '12'].indexOf(value1.certificateType) > -1) {
          hasMustChinaCertificate = true;
        }
      })

      const regExp = hasMustChinaCertificate ? /^[\u4e00-\u9fa5]+$/ : /^[\u0391-\uFFE5A-Za-z]+$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(hasMustChinaCertificate ? '只能输入中文' : '只能输入中文、英文')
      }
    }
  },
  mounted () {
    this.req_list(this.form);
    this.req_nationality();
    this.getCertificateType();
  },
  activated () {

  },
  deactivated () {

  }
}
