// 删除外部客户接口文档地址：
// http://192.168.0.13:8765/doc.html#/haolv-consumer/t-us-external-customer-controller/delExternalCustomerUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_externalCustomer_delExternalCustomer = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/externalCustomer/delExternalCustomer',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_externalCustomer_delExternalCustomer;